import { CoPresent, Home, School } from '@mui/icons-material';
import {
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { bool, string } from 'prop-types';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import SystemAlert from 'src/components/general/SystemAlert';
import Header from '../Header';

const drawerWidth = '260px';

function ProtectedMain({
  isAllowed,
  redirectPath = '/login',
}) {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const [open, setOpen] = useState(matches);
  const navigate = useNavigate();

  const menu = [
    {
      icon: <Home />,
      text: 'Pagina principală',
      link: '/'
    },
    {
      icon: <School />,
      text: 'Cursuri',
      link: '/cursuri'
    },
    {
      icon: <CoPresent />,
      text: 'Organizatori',
      link: '/organizatori'
    }
  ];

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <Header toggleDrawer={toggleDrawer} />
      <Box
        sx={{
          textAlign: 'center',
          width: '100%',
          p: 2
        }}
      >
        <Drawer
          variant={!matches ? 'temporary' : 'permanent'}
          open={open}
          onClose={toggleDrawer}
          PaperProps={{
            sx: {
              width: drawerWidth
            }
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: 'auto' }}>
            <List>
              {menu.map((item) => (
                <ListItemButton key={item.link} onClick={() => navigate(item.link)}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText>{item.text}</ListItemText>
                </ListItemButton>
              ))}
            </List>
          </Box>
        </Drawer>
        <Box sx={{ marginLeft: matches ? drawerWidth : 0 }}>
          <Outlet />
        </Box>
      </Box>
      <SystemAlert />
    </>
  );
}

ProtectedMain.propTypes = {
  isAllowed: bool,
  redirectPath: string
};

export default ProtectedMain;
