import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loggedIn: false,
  user: null,
  token: null
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      state.loggedIn = initialState.loggedIn;
      state.user = initialState.user;
      state.token = initialState.token;
    },
    login: (state, action) => {
      state.loggedIn = true;
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    refreshToken: (state, action) => {
      state.token = action.payload.token;
    }
  }
});

export const {
  logout, login, refreshToken
} = userSlice.actions;

export default userSlice.reducer;
