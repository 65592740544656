import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { func } from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { showNotification } from 'src/redux/features/app/appSlice';
import ClientAPI from 'src/services/client.service';
import OrganizerAPI from 'src/services/organizer.service';

function OrganizerAddForm({ successCallback }) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const schema = Yup.object({
    clientId: Yup.number().nullable(true).required('Câmp obligatoriu')
  });

  const formik = useFormik({
    initialValues: {
      clientId: null
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      const response = await OrganizerAPI.add(values.clientId);

      if (response.code !== undefined) {
        if (response.code === '23000') {
          dispatch(showNotification({
            type: 'error',
            message: 'Acest organizator există deja.'
          }));
        } else {
          dispatch(showNotification({
            type: 'error',
            message: 'A apărut o eroare la salvare.'
          }));
        }
      } else {
        dispatch(showNotification({
          type: 'success',
          message: 'Organizatorul a fost adaugat cu success'
        }));

        successCallback();
      }
    }
  });

  const searchDelay = 300;
  let searchTimeout = null;
  const handleInputChange = (event, value) => {
    clearTimeout(searchTimeout);
    setLoading(true);

    let active = true;

    if (value.length > 2) {
      (async () => {
        if (active) {
          searchTimeout = setTimeout(async () => {
            const response = await ClientAPI.search(value);
            setOptions(response);
            setLoading(false);
          }, searchDelay);
        }
      })();
    }

    return () => {
      active = false;
    };
  };

  return (
    <form id="addNewOrganizerForm" onSubmit={formik.handleSubmit}>
      <Autocomplete
        id="client-autocomplete"
        sx={{ width: '100%', mt: 2 }}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onInputChange={handleInputChange}
        onChange={(event, value) => { formik.setFieldValue('clientId', value.id); }}
        isOptionEqualToValue={(option, value) => `${option.nume} ${option.prenume}` === `${value.nume} ${value.prenume}`}
        getOptionLabel={(option) => `${option.nume} ${option.prenume}`}
        options={options}
        loading={loading}
        noOptionsText="Nu sunt rezultate"
        renderInput={(params) => (
          <TextField
            {...params}
            label="Client"
            onBlur={formik.handleBlur}
            error={formik.touched.clientId && (formik.errors.clientId !== '')}
            helperText={(formik.touched.clientId && formik.errors.clientId) ? formik.errors.clientId : ''}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
      />
    </form>
  );
}

OrganizerAddForm.propTypes = {
  successCallback: func
};

export default OrganizerAddForm;
