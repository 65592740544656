import { Box, Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import loginThunk from 'src/redux/features/user/thunks/loginThunk';
import * as Yup from 'yup';

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginSchema = Yup.object({
    username: Yup.string().required('Câmp obligatoriu'),
    password: Yup.string().required('Câmp obligatoriu')
  });

  const submitForm = async (values) => {
    dispatch(loginThunk(values, navigate));
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors
  } = useFormik({
    initialValues: {
      username: '',
      password: ''
    },
    validationSchema: loginSchema,
    onSubmit: submitForm
  });

  return (
    <Box sx={{ maxWidth: '320px', width: '100%', margin: '40px auto' }}>
      <form onSubmit={handleSubmit}>
        <TextField
          required
          fullWidth
          id="username"
          label="Utilizator"
          name="username"
          autoFocus
          value={values.username}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.username && (errors.username !== '')}
          helperText={(touched.username && errors.username) ? errors.username : ''}
          sx={{
            mb: 2
          }}
          inputProps={{
            style: {
              backgroundColor: 'white'
            }
          }}
        />
        <TextField
          required
          fullWidth
          name="password"
          label="Parola"
          type="password"
          id="password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.password && (errors.password !== '')}
          helperText={(touched.password && errors.password) ? errors.password : ''}
          sx={{
            mb: 1
          }}
          inputProps={{
            style: {
              backgroundColor: 'white'
            }
          }}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Logare
        </Button>
      </form>
    </Box>
  );
}

export default Login;
