import axios from 'axios';
import authHeader from 'src/utils/auth/authHeaders';

class OrganizerAPI {
  static async list() {
    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}organizers?expand=client`,
        headers: authHeader()
      });

      return response.data.items;
    } catch (e) {
      return [];
    }
  }

  static async get(id) {
    try {
      if (id) {
        const response = await axios({
          method: 'GET',
          url: `${process.env.REACT_APP_API_URL}organizers/${id}?expand=client`,
          headers: authHeader()
        });

        return response.data;
      }
    } catch (e) {
      console.error(e.message);
    }

    return [];
  }

  static async add(id) {
    try {
      if (id) {
        const response = await axios({
          method: 'POST',
          url: `${process.env.REACT_APP_API_URL}organizers`,
          headers: authHeader(),
          data: {
            client_id: id
          }
        });

        return response.data;
      }
    } catch (e) {
      return e.response.data;
    }

    return [];
  }

  static async delete(id) {
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${process.env.REACT_APP_API_URL}organizers/${id}`,
        headers: authHeader()
      });

      return response.data;
    } catch (e) {
      console.error(e.message);
    }

    return false;
  }
}

export default OrganizerAPI;
