import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Router from './routes/Router';
import theme from './style/theme';
import './style/GlobalStyles.css';
import tokenRefreshInterceptor from './utils/auth/tokenRefreshInterceptor';

function App() {
  const navigate = useNavigate();

  tokenRefreshInterceptor(navigate);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router />
    </ThemeProvider>
  );
}

export default App;
