import { InsertLink, Numbers } from '@mui/icons-material';
import {
  CircularProgress,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { number } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import EditionAPI from 'src/services/edition.service';
import DeleteButton from 'src/components/general/DeleteButton';
import AddNewEdition from '../AddNewEdition';

function EditionList({ courseId }) {
  const navigate = useNavigate();

  const { data: editions, isFetched: editionFetched, refetch: refetchEditions } = useQuery(['getEditions', courseId], () => EditionAPI.list(courseId));

  const handleAddition = () => {
    refetchEditions();
  };

  const handleDelete = async (editionId) => {
    await EditionAPI.delete(editionId);

    refetchEditions();
  };

  return (
    <List component="div" disablePadding>
      { !editionFetched ? <CircularProgress /> : editions.map((edition) => (
        <ListItemButton sx={{ pl: 4, backgroundColor: edition.payment_proof ? '#d4f1d4' : 'inherit' }} key={edition.id} onClick={() => navigate(`/participanti/${edition.id}`)}>
          <ListItemIcon>
            <Numbers />
          </ListItemIcon>
          <ListItemText primary={edition.name} />
          {edition.payment_proof === '' && (
            <>
              <a href={`/participanti/inregistrare/${edition.hash}`} onClick={(e) => e.stopPropagation()} target="_blank" rel="nofollow noreferrer">
                <IconButton>
                  <InsertLink />
                </IconButton>
              </a>
              <DeleteButton handler={() => handleDelete(edition.id)} />
            </>
          )}
        </ListItemButton>
      ))}
      <AddNewEdition courseId={courseId} successCallback={handleAddition} />
    </List>
  );
}

EditionList.propTypes = {
  courseId: number.isRequired
};

export default EditionList;
