import axios from 'axios';
import { Buffer } from 'buffer';

export default async (hash) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}course-editions?filter[hash][eq]=${hash}`,
      headers: {
        Authorization: `Basic ${Buffer.from(process.env.REACT_APP_API_KEY).toString('base64')}`
      }
    });

    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    console.error('There was a problem connecting to the server.');
  }

  return null;
};
