import {
  Add,
  Check,
  Email,
  Person,
  Phone,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Link,
  Button,
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { showNotification } from 'src/redux/features/app/appSlice';
import getCourseEditionByHash from 'src/services/course-edition/getCourseEditionByHash';
import getCourse from 'src/services/course/getCourse';
import saveRegistration from 'src/services/participant/saveRegistration';
import validateSignupData from 'src/services/participant/validateSignupData';

function RegistrationForm() {
  const formReducer = (state, action) => {
    switch (action.type) {
      case 'reset':
        return {
          course_id: state.course_id,
          edition_id: state.edition_id
        };
      default:
        return {
          ...state,
          [action.name]: action.value
        };
    }
  };

  const { hash } = useParams();

  const [formData, setFormData] = useReducer(formReducer, {});

  const [saving, setSaving] = useState(false);
  const [finished, setFinished] = useState(false);
  const [courseName, setCourseName] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const checkHash = async () => {
      const courseEdition = await getCourseEditionByHash(hash);

      if (courseEdition === null) {
        navigate('/error-500');
      } else if (courseEdition.items.length > 0) {
        const course = await getCourse(courseEdition.items[0].course_id);
        if (course) {
          setCourseName(course.name);

          setFormData({
            name: 'edition_id',
            value: courseEdition.items[0].id
          });

          setFormData({
            name: 'organizer_id',
            value: course.organizer.client_id
          });

          setFormData({
            name: 'course_name',
            value: course.name
          });

          setFormData({
            name: 'course_id',
            value: course.id
          });
        } else {
          navigate('/404');
        }
      } else {
        navigate('/404');
      }
    };

    checkHash(hash);
  }, []);

  const handleChange = (event) => {
    setFormData({
      name: event.target.name,
      value: event.target.value
    });
  };

  const handleSubmit = async () => {
    setSaving(true);

    // Validate data
    const validationResponse = validateSignupData(formData);

    if (validationResponse === true) {
      // Save data
      const registrationResponse = await saveRegistration(formData);

      if (registrationResponse === true) {
        // Reset form
        setFormData({ type: 'reset' });
        setFinished(true);
      } else {
        // Show success notification
        dispatch(showNotification({
          type: 'error',
          message: registrationResponse
        }));
      }
    } else {
      dispatch(showNotification({
        type: 'error',
        message: validationResponse
      }));
    }

    setSaving(false);
  };

  return (
    <Box sx={{ textAlign: 'left' }}>
      <Typography
        variant="h5"
        sx={{
          mb: 2,
          mt: 2,
          color: 'primary.main',
          fontWeight: 'bold'
        }}
      >
        Formular de participare la curs
      </Typography>
      { !finished ? (
        <>
          <Typography variant="body2">
            Prin completarea prezentului chestionar dovedesc faptul că am participat
            la: &nbsp;
            <b>{courseName}</b>
          </Typography>

          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="nume"
                variant="outlined"
                label="Nume"
                value={formData.nume || ''}
                onChange={handleChange}
                sx={{
                  width: '100%',
                  background: 'white'
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Person />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="prenume"
                variant="outlined"
                label="Prenume"
                value={formData.prenume || ''}
                onChange={handleChange}
                sx={{
                  width: '100%',
                  background: 'white'
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Person />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="email"
                variant="outlined"
                label="Adresa de e-mail"
                value={formData.email || ''}
                onChange={handleChange}
                sx={{
                  width: '100%',
                  background: 'white'
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="mobil"
                variant="outlined"
                label="Nr. mobil"
                value={formData.mobil || ''}
                onChange={handleChange}
                sx={{
                  width: '100%',
                  background: 'white'
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Phone />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ lineHeight: '1.3' }}>
              <Typography variant="caption">
                Prin completarea chestionarului, sunteti de acord cu&nbsp;
                <Link
                  href="https://www.sisicosmetice.ro/termeni-si-conditii.html"
                  target="_blank"
                  rel="noreferrer"
                  sx={{ color: 'primary.main' }}
                >
                  Termenii și conditiile
                </Link>
                &nbsp;și cu&nbsp;
                <Link
                  href="https://www.sisicosmetice.ro/prelucrarea-datelor-cu-caracter-personal.html"
                  target="_blank"
                  rel="noreferrer"
                  sx={{ color: 'primary.main' }}
                >
                  Politica de Confidențialitate.
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'right', mt: 3 }}>
              <LoadingButton
                loading={saving}
                variant="outlined"
                onClick={handleSubmit}
              >
                <Check />
                Inregistrare
              </LoadingButton>
            </Grid>
          </Grid>
        </>
      ) : (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h6" sx={{ my: 5 }}>
            V-ați înregistrat cu success!
          </Typography>
          <Button
            variant="outlined"
            startIcon={<Add />}
            onClick={() => setFinished(false)}
          >
            Adaugă încă o persoană
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default RegistrationForm;
