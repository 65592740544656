import axios from 'axios';

class AuthAPI {
  static logout() {
    return axios({
      method: 'DELETE',
      url: `${process.env.REACT_APP_API_URL}auth/refresh-token`
    });
  }

  static login(data) {
    return axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}auth/login`,
      withCredentials: true,
      data: {
        LoginForm: data
      }
    });
  }

  static refreshToken() {
    return axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}auth/refresh-token`,
      withCredentials: true
    });
  }
}

export default AuthAPI;
