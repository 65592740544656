import { Add } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { func } from 'prop-types';
import { useState } from 'react';
import CourseAddForm from './CourseAddForm';

function AddNewCourse({ successCallback }) {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleSuccess = () => {
    setDialogOpen(false);

    successCallback();
  };

  return (
    <>
      <Button
        variant="contained"
        startIcon={<Add />}
        sx={{ mt: 3 }}
        onClick={() => setDialogOpen(true)}
      >
        Adaugă curs nou
      </Button>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Adaugă curs nou</DialogTitle>
        <DialogContent sx={{ width: '500px', maxWidth: '100%' }}>
          <CourseAddForm successCallback={handleSuccess} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Renunță</Button>
          <Button form="addNewCourseForm" type="submit" autoFocus>
            Adaugă
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

AddNewCourse.propTypes = {
  successCallback: func
};

export default AddNewCourse;
