import axios from 'axios';
import moment from 'moment';
import authHeader from 'src/utils/auth/authHeaders';

class EditionAPI {
  static async list(courseId) {
    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}course-editions?filter[course_id][eq]=${courseId}`,
        headers: authHeader()
      });

      return response.data.items;
    } catch (e) {
      console.error(e.message);
      return [];
    }
  }

  static async get(id) {
    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}course-editions/${id}?expand=course`,
        headers: authHeader()
      });

      return response.data;
    } catch (e) {
      return [];
    }
  }

  static async add(data) {
    try {
      if (data) {
        const response = await axios({
          method: 'POST',
          url: `${process.env.REACT_APP_API_URL}course-editions`,
          headers: authHeader(),
          data: {
            course_id: data.courseId,
            name: data.denumire,
            start_date: data.dataInceput?.format('YYYY-MM-DD'),
            end_date: data.dataSfarsit?.format('YYYY-MM-DD'),
            credit: data.suma,
            duration: data.durata
          }
        });

        return response.data;
      }
    } catch (e) {
      return e.response.data;
    }

    return [];
  }

  static async delete(id) {
    try {
      if (id) {
        const response = await axios({
          method: 'DELETE',
          url: `${process.env.REACT_APP_API_URL}course-editions/${id}`,
          headers: authHeader()
        });

        return response.data;
      }
    } catch (e) {
      console.log(e.message);
    }

    return false;
  }

  static async update(id, data) {
    try {
      if (data) {
        const response = await axios({
          method: 'PATCH',
          url: `${process.env.REACT_APP_API_URL}course-editions/${id}`,
          headers: authHeader(),
          data: {
            payment_proof: data.paymentProof,
            payment_date: moment(data.paymentDate).unix()
          }
        });

        return response.data;
      }
    } catch (e) {
      return e.response.data;
    }

    return [];
  }
}

export default EditionAPI;
