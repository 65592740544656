import AuthAPI from 'src/services/auth.service';
import { showNotification } from '../../app/appSlice';
import { login } from '../userSlice';

export default (data, navigate) => async (dispatch) => {
  try {
    const response = await AuthAPI.login(data);

    const { user, token } = response.data;

    dispatch(login({
      user, token
    }));

    dispatch(showNotification({
      type: 'success',
      message: 'V-ati logat cu success'
    }));

    navigate('/');
  } catch (e) {
    dispatch(showNotification({
      type: 'error',
      message: (e.response.data === undefined) ? 'A aparut o eroare de conexiune' : e.response.data.message
    }));
  }
};
