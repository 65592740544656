import axios from 'axios';
import { Buffer } from 'buffer';

export default async (id) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}courses/${id}?expand=organizer`,
      headers: {
        Authorization: `Basic ${Buffer.from(process.env.REACT_APP_API_KEY).toString('base64')}`
      }
    });

    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    console.error('There was a problem connection to the server.');
  }

  return null;
};
