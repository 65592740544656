import { Person } from '@mui/icons-material';
import {
  CircularProgress,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography
} from '@mui/material';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import DeleteButton from 'src/components/general/DeleteButton';
import AddNewOrganizer from 'src/components/organizer/AddNewOrganizer';
import OrganizerAPI from 'src/services/organizer.service';

function OrganizerList() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: organizatori, isFetched: organizatoriFetched, refetch: refetchOrganizator } = useQuery('getOrganizatori', () => OrganizerAPI.list());

  const handleAddition = () => {
    refetchOrganizator();
  };

  const handleDelete = async (organizerId) => {
    await OrganizerAPI.delete(organizerId);

    queryClient.invalidateQueries(['getCourses']);
    refetchOrganizator();
  };

  return (
    <>
      <Grid container sx={{ alignItems: 'center' }}>
        <Grid item xs={8}>
          <Grid item sm={8} xs={12} sx={{ textAlign: 'left' }}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Organizatori
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {!organizatoriFetched ? (
        <CircularProgress />
      ) : (
        <Paper>
          <List>
            {organizatori.length === 0 ? (
              <Typography>Nu există cursuri</Typography>
            ) : (
              organizatori.map((organizator) => (
                <ListItemButton key={organizator.id} onClick={() => navigate(`/organizatori/cursuri/${organizator.id}`)}>
                  <ListItemIcon>
                    <Person />
                  </ListItemIcon>
                  <ListItemText>{`${organizator.client.nume} ${organizator.client.prenume}`}</ListItemText>
                  <DeleteButton handler={() => handleDelete(organizator.id)} />
                </ListItemButton>
              ))
            )}
          </List>
        </Paper>
      )}
      <AddNewOrganizer successCallback={handleAddition} />
    </>
  );
}

export default OrganizerList;
