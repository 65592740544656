import emailValidation from 'src/utils/string/emailValidation';
import phoneValidation from 'src/utils/string/phoneValidation';

export default (data) => {
  if (
    data.nume === '' || data.prenume === '' || data.email === '' || data.mobil === ''
    || data.nume === undefined || data.prenume === undefined || data.email === undefined || data.mobil === undefined
    || data.course_id === undefined || data.edition_id === undefined
  ) {
    return 'Toate campurile sunt obligatorii';
  }

  if (!emailValidation(data.email)) {
    return 'Adresa de e-mail nu este validă';
  }

  if (!phoneValidation(data.mobil)) {
    return 'Numărul de telefon nu este valid';
  }

  return true;
};
