import {
  ChevronLeft, ExpandLess, ExpandMore, MenuBook
} from '@mui/icons-material';
import {
  Avatar,
  Breadcrumbs,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography
} from '@mui/material';
import { Fragment, useState } from 'react';
import { useQuery } from 'react-query';
import CourseAPI from 'src/services/course.service';
import EditionList from 'src/components/courses/EditionList';
import { Link, useNavigate, useParams } from 'react-router-dom';
import OrganizerAPI from 'src/services/organizer.service';
import AddNewCourse from 'src/components/courses/AddNewCourse';
import DeleteButton from 'src/components/general/DeleteButton';

function CourseList() {
  const [courseOpen, setCourseOpen] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();

  const handleListToggle = (courseId) => {
    if (courseOpen.includes(courseId)) {
      setCourseOpen((prev) => prev.filter((course) => course !== courseId));
    } else {
      setCourseOpen([...courseOpen, courseId]);
    }
  };

  const { data: courses, isFetched: courseFetched, refetch: refetchCourses } = useQuery(
    ['getCourses', id],
    () => {
      if (id !== undefined) {
        return CourseAPI.listByOrganizer(id);
      }

      return CourseAPI.list();
    }
  );

  const { data: organizer, isFetched: organizerFetched } = useQuery(['getOrganizer', id], () => OrganizerAPI.get(id));

  const handleAddition = () => {
    refetchCourses();
  };

  const deleteCourse = async (courseId) => {
    await CourseAPI.delete(courseId);

    refetchCourses();
  };

  return (
    <>
      <Grid container sx={{ alignItems: 'center' }}>
        { id && (
          <>
            <Grid item xs={8}>
              <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ mb: 2 }}>
                <Link to="/organizatori">
                  <Typography sx={{ color: '#a3a3a3' }}>
                    Organizatori
                  </Typography>
                </Link>
                <Typography color="#a3a3a3">{organizerFetched ? `${organizer.client.nume} ${organizer.client.prenume}` : ''}</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item xs={4} sx={{ textAlign: 'right' }}>
              <Button
                onClick={() => navigate('/organizatori')}
                size="small"
                sx={{ mb: 2 }}
              >
                <ChevronLeft />
                &nbsp;Înapoi
              </Button>
            </Grid>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Divider />
            </Grid>
          </>
        )}
        <Grid item sm={8} xs={12} sx={{ textAlign: 'left' }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Cursuri
          </Typography>
        </Grid>
      </Grid>
      {!courseFetched ? (
        <CircularProgress />
      ) : (
        <Paper>
          <List>
            {courses.length === 0 ? (
              <Typography>Nu există cursuri</Typography>
            ) : (
              courses.map((course, key) => (
                <Fragment key={course.id}>
                  <ListItemButton onClick={() => handleListToggle(course.id)}>
                    <ListItemIcon>
                      <Avatar>
                        <MenuBook />
                      </Avatar>
                    </ListItemIcon>
                    <ListItemText>{`${course.name} - ${course.organizer.client.nume} ${course.organizer.client.prenume}`}</ListItemText>
                    <DeleteButton handler={() => deleteCourse(course.id)} />
                    {courseOpen.includes(course.id) ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItemButton>
                  <Collapse
                    in={courseOpen.includes(course.id)}
                    timeout="auto"
                    unmountOnExit
                    sx={{
                      backgroundColor: '#f9f9f9',
                      boxShadow: 'inset 0px 7px 9px -11px'
                    }}
                  >
                    {courseOpen.includes(course.id) && (
                      <EditionList courseId={course.id} />
                    )}
                  </Collapse>
                  {key + 1 !== courses.length && <Divider />}
                </Fragment>
              ))
            )}
          </List>
        </Paper>
      )}
      <AddNewCourse successCallback={handleAddition} />
    </>
  );
}

export default CourseList;
