const { createTheme } = require('@mui/material/styles');

const theme = createTheme({
  palette: {
    background: {
      default: '#f3eef0'
    },
    primary: {
      main: '#eb366b'
    },
    secondary: {
      main: '#363636'
    }
  }
});

export default theme;
