import { Alert, Snackbar } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showNotification } from 'src/redux/features/app/appSlice';

function SystemAlert() {
  const notification = useSelector((state) => state.app.notification);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleSnakClose = () => {
    setOpen(false);
    dispatch(showNotification({}));
  };

  useEffect(() => {
    if (notification.message !== undefined) setOpen(true);
    else setOpen(false);
  }, [notification]);

  return (notification.message !== undefined) && (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={handleSnakClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert severity={notification.type} onClose={handleSnakClose} variant="filled">
        {notification.message}
      </Alert>
    </Snackbar>
  );
}

export default SystemAlert;
