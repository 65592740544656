import axios from 'axios';
import authHeader from 'src/utils/auth/authHeaders';

class ParticipantAPI {
  static async listInEdition(editionId) {
    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}participants?filter[edition_id][eq]=${editionId}`,
        headers: authHeader()
      });

      return response.data.items;
    } catch (e) {
      return [];
    }
  }

  static async delete(id) {
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${process.env.REACT_APP_API_URL}participants/${id}`,
        headers: authHeader()
      });

      return response.data;
    } catch (e) {
      console.error(e.message);
    }

    return false;
  }
}

export default ParticipantAPI;
