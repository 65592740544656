import { BrokenImage, KeyboardReturn } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function ErrorPage() {
  const navigate = useNavigate();

  return (
    <>
      <BrokenImage sx={{ fontSize: '8rem', color: 'primary.main', mt: 3 }} />
      <Typography variant="h1" sx={{ fontSize: '6rem' }}>500</Typography>
      <Typography variant="body1">A aparut o eroare la conexiune</Typography>
      <Button onClick={() => navigate('/')} startIcon={<KeyboardReturn />} sx={{ mt: 3 }}>Inapoi la prima pagina</Button>
    </>
  );
}

export default ErrorPage;
