import { Typography } from '@mui/material';
import { Box } from '@mui/system';

function Home() {
  return (
    <Box sx={{ my: 10 }}>
      <Typography variant="body1" sx={{ mb: 3 }}>
        Bine ati venti pe interfata
        <br />
        <b>SisiEdu</b>
      </Typography>
    </Box>
  );
}

export default Home;
