import { useTheme } from '@emotion/react';
import { Logout, Menu } from '@mui/icons-material';
import {
  AppBar, Box, Button, IconButton, Toolbar, useMediaQuery
} from '@mui/material';
import { func } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import logoutThunk from 'src/redux/features/user/thunks/logoutThunk';
import logo from '../../assets/images/logo.png';

function Header({ toggleDrawer }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const user = useSelector((state) => state.user);
  const showMenu = user.loggedIn;

  return (
    <Box>
      <AppBar position="fixed" sx={{ zIndex: 1300 }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ width: '80px' }}>
            {showMenu && !matches && (
              <IconButton
                onClick={toggleDrawer}
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ width: '60px' }}
              >
                <Menu />
              </IconButton>
            )}
          </Box>
          <Link to="/">
            <img
              src={logo}
              alt="Sisi Education"
              width={90}
              style={{ verticalAlign: 'bottom' }}
            />
          </Link>
          <Box sx={{ width: '80px' }}>
            { user.loggedIn && (
              <Button
                color="inherit"
                startIcon={<Logout />}
                onClick={() => dispatch(logoutThunk(navigate))}
              >
                Logout
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </Box>
  );
}

Header.propTypes = {
  toggleDrawer: func
};

export default Header;
