import store from 'src/redux/store';

export default function authHeader() {
  const state = store.getState();

  if (state.user.loggedIn && state.user.token) {
    return { Authorization: `Bearer ${state.user.token}` };
  }

  return {};
}
