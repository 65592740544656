import { Add } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';
import { func } from 'prop-types';
import { useState } from 'react';
import OrganizerAddForm from './OrganizerAddForm';

function AddNewOrganizer({ successCallback }) {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleSuccess = () => {
    setDialogOpen(false);

    successCallback();
  };

  return (
    <>
      <Button
        variant="contained"
        startIcon={<Add />}
        sx={{ mt: 3 }}
        onClick={() => setDialogOpen(true)}
      >
        Adaugă organizator
      </Button>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Adaugă organizator nou</DialogTitle>
        <DialogContent>
          <Typography>
            Selectează un client existent din baza de date:
          </Typography>
          <OrganizerAddForm successCallback={handleSuccess} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Renunță</Button>
          <Button form="addNewOrganizerForm" type="submit" autoFocus>
            Adaugă
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

AddNewOrganizer.propTypes = {
  successCallback: func
};

export default AddNewOrganizer;
