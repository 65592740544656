import { Android, KeyboardReturn } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function NotFound() {
  const navigate = useNavigate();

  return (
    <>
      <Android sx={{ fontSize: '11rem', color: 'primary.main' }} />
      <Typography variant="h1" sx={{ fontSize: '8rem', marginTop: '-30px' }}>404</Typography>
      <Typography variant="body1">Pagina nu a fost gasita</Typography>
      <Button onClick={() => navigate('/')} startIcon={<KeyboardReturn />} sx={{ mt: 3 }}>Inapoi la prima pagina</Button>
    </>
  );
}

export default NotFound;
