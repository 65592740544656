import axios from 'axios';
import authHeader from 'src/utils/auth/authHeaders';

class CourseAPI {
  static async list() {
    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}courses?expand=organizer.client`,
        headers: authHeader()
      });

      return response.data.items;
    } catch (e) {
      return [];
    }
  }

  static async listByOrganizer(id) {
    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}courses?expand=organizer.client&filter[organizer_id][eq]=${id}`,
        headers: authHeader()
      });

      return response.data.items;
    } catch (e) {
      return [];
    }
  }

  static async get(id) {
    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}courses/${id}`,
        headers: authHeader()
      });

      return response.data;
    } catch (e) {
      return [];
    }
  }

  static async add(data) {
    try {
      if (data) {
        const response = await axios({
          method: 'POST',
          url: `${process.env.REACT_APP_API_URL}courses`,
          headers: authHeader(),
          data: {
            organizer_id: data.organizatorId,
            name: data.denumire
          }
        });

        return response.data;
      }
    } catch (e) {
      return e.response.data;
    }

    return [];
  }

  static async delete(id) {
    try {
      if (id) {
        const response = await axios({
          method: 'DELETE',
          url: `${process.env.REACT_APP_API_URL}courses/${id}`,
          headers: authHeader()
        });

        return response.data;
      }
    } catch (e) {
      console.error(e.message);
    }

    return false;
  }
}

export default CourseAPI;
