import AuthAPI from 'src/services/auth.service';
import { showNotification } from '../../app/appSlice';
import { logout } from '../userSlice';

export default (navigate, url) => async (dispatch) => {
  try {
    await AuthAPI.logout();
  } catch (e) {
    dispatch(showNotification({
      type: 'error',
      message: 'Nu s-a putut efectua dezlogarea.'
    }));
  }

  dispatch(logout());

  if (navigate) {
    navigate(url || '/');
  }
};
