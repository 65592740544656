import { DatePicker, LocalizationProvider } from '@mui/lab';
import { TextField } from '@mui/material';
import { useFormik } from 'formik';
import { func, number } from 'prop-types';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { showNotification } from 'src/redux/features/app/appSlice';
import EditionAPI from 'src/services/edition.service';

function PayEditionForm({ editionId, successCallback }) {
  const dispatch = useDispatch();

  const schema = Yup.object({
    paymentProof: Yup.string().required('Câmp obligatoriu'),
    paymentDate: Yup.string().nullable(true)
  });

  const formik = useFormik({
    initialValues: {
      paymentProof: '',
      paymentDate: null,
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      const response = await EditionAPI.update(editionId, values);

      if (response.code !== undefined) {
        dispatch(
          showNotification({
            type: 'error',
            message: 'A apărut o eroare la salvare.'
          })
        );
      } else {
        dispatch(
          showNotification({
            type: 'success',
            message: 'Ediția a fost platita cu success'
          })
        );

        successCallback();
      }
    }
  });

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <form id="updateEditionForm" onSubmit={formik.handleSubmit}>
        <TextField
          name="paymentProof"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.paymentProof && (formik.errors.paymentProof !== '')}
          helperText={(formik.touched.paymentProof && formik.errors.paymentProof) ? formik.errors.paymentProof : ''}
          label="Document plata"
          sx={{ minWidth: '100%', mt: 1 }}
        />
        <DatePicker
          label="Data platii"
          value={formik.values.paymentDate ? formik.values.paymentDate.format('YYYY-MM-DD') : null}
          onChange={(value) => formik.setFieldValue('paymentDate', value)}
          renderInput={(params) => (
            <TextField
              name="paymentDate"
              {...params}
              sx={{ minWidth: '100%', mt: 2 }}
            />
          )}
        />
      </form>
    </LocalizationProvider>
  );
}

PayEditionForm.propTypes = {
  editionId: number.isRequired,
  successCallback: func
};

export default PayEditionForm;
