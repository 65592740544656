import AuthAPI from 'src/services/auth.service';
import { refreshToken } from '../userSlice';

export default () => async (dispatch) => {
  const response = await AuthAPI.refreshToken();

  if (response.status === 200) {
    dispatch(refreshToken(response.data));
  }

  return response.data.token;
};
