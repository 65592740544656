import { Delete } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material';
import { func, string } from 'prop-types';
import { useState } from 'react';

function DeleteButton({ handler, dialogTitle, dialogText }) {
  const [open, setOpen] = useState(false);

  const title = dialogTitle || 'Confirmare stergere';
  const text = dialogText || 'Esti sigur ca doresti sa o stergi?';

  const confirmDelete = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setOpen(true);
  };

  const handleReject = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setOpen(false);
  };

  const handleAccept = (event) => {
    event.preventDefault();
    event.stopPropagation();

    handler();
  };

  return (
    <>
      <IconButton onClick={confirmDelete}>
        <Delete />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Typography>{text}</Typography>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={handleReject}>Renunta</Button>
          <Button variant="contained" onClick={handleAccept}>
            Sterge
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

DeleteButton.propTypes = {
  handler: func.isRequired,
  dialogText: string,
  dialogTitle: string
};

export default DeleteButton;
