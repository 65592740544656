import { configureStore } from '@reduxjs/toolkit';
import { throttle } from 'lodash';
import appReducer from './features/app/appSlice';
import userReducer from './features/user/userSlice';
import { loadState, saveState } from './utils';

// Load saved state
const persistedState = loadState();

const store = configureStore({
  reducer: {
    app: appReducer,
    user: userReducer
  },
  preloadedState: persistedState
});

// Save/Update state when something changes
store.subscribe(throttle(() => {
  saveState({
    app: store.getState().app,
    user: store.getState().user
  });
}, 1000));

export default store;
