import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { func } from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { showNotification } from 'src/redux/features/app/appSlice';
import CourseAPI from 'src/services/course.service';
import OrganizerAPI from 'src/services/organizer.service';

function CourseAddForm({ successCallback }) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;
  const dispatch = useDispatch();

  const schema = Yup.object({
    organizatorId: Yup.number().nullable(true).required('Câmp obligatoriu'),
    denumire: Yup.string().required('Câmp obligatoriu')
  });

  const formik = useFormik({
    initialValues: {
      organizatorId: null,
      denumire: ''
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      const response = await CourseAPI.add(values);

      if (response.code !== undefined) {
        dispatch(showNotification({
          type: 'error',
          message: 'A apărut o eroare la salvare.'
        }));
      } else {
        dispatch(showNotification({
          type: 'success',
          message: 'Cursul a fost adaugat cu success'
        }));

        successCallback();
      }
    }
  });

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const organizers = await OrganizerAPI.list();

      if (active) {
        setOptions([...organizers]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  return (
    <form id="addNewCourseForm" onSubmit={formik.handleSubmit}>
      <TextField
        name="denumire"
        onChange={formik.handleChange}
        label="Denumire"
        sx={{ width: '100%', mt: 1 }}
        onBlur={formik.handleBlur}
        error={formik.touched.denumire && (formik.errors.denumire !== '')}
        helperText={(formik.touched.denumire && formik.errors.denumire) ? formik.errors.denumire : ''}
      />
      <Autocomplete
        id="organizator-autocomplete"
        sx={{ width: '100%', mt: 2 }}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={(event, value) => { formik.setFieldValue('organizatorId', value.id); }}
        isOptionEqualToValue={(option, value) => `${option.client.nume} ${option.client.prenume}` === `${value.client.nume} ${value.client.prenume}`}
        getOptionLabel={(option) => `${option.client.nume} ${option.client.prenume}`}
        options={options}
        loading={loading}
        noOptionsText="Nu sunt rezultate"
        renderInput={(params) => (
          <TextField
            {...params}
            label="Organizator"
            onBlur={formik.handleBlur}
            error={formik.touched.organizatorId && (formik.errors.organizatorId !== '')}
            helperText={(formik.touched.organizatorId && formik.errors.organizatorId) ? formik.errors.organizatorId : ''}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
      />
    </form>
  );
}

CourseAddForm.propTypes = {
  successCallback: func
};

export default CourseAddForm;
