import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import SystemAlert from 'src/components/general/SystemAlert';
import Header from '../Header';

function PublicMain() {
  return (
    <>
      <Header />
      <Box
        sx={{
          textAlign: 'center',
          width: '100%',
          p: 2
        }}
      >
        <Outlet />
      </Box>
      <SystemAlert />
    </>
  );
}

export default PublicMain;
