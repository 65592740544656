import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  notification: {}
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    showNotification: (state, action) => {
      state.notification = action.payload;
    }
  }
});

export const {
  showNotification
} = appSlice.actions;

export default appSlice.reducer;
