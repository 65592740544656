import { useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import ProtectedMain from 'src/layout/ProtectedMain';
import PublicMain from 'src/layout/PublicMain';
import Login from 'src/pages/authentication/Login';
import CourseList from 'src/pages/course/CourseList';
import OrganizerList from 'src/pages/organizer/OrganizerList';
import ParticipantList from 'src/pages/course/participant/ParticipantList';
import RegistrationForm from 'src/pages/course/participant/RegistrationForm';
import ErrorPage from 'src/pages/ErrorPage';
import Home from 'src/pages/Home';
import NotFound from 'src/pages/NotFound';

function Router() {
  const user = useSelector((state) => state.user);
  const element = useRoutes([
    {
      path: `${process.env.PUBLIC_URL}/`,
      element: <PublicMain />,
      children: [
        { path: 'login', element: <Login /> },

        { path: 'participanti/inregistrare/:hash', element: <RegistrationForm /> },

        { path: 'error-500', element: <ErrorPage /> },

        { path: '*', element: <NotFound /> }
      ]
    },
    {
      path: `${process.env.PUBLIC_URL}/`,
      element: <ProtectedMain isAllowed={user.loggedIn} />,
      children: [
        { index: true, element: <Home /> },

        { path: 'participanti/:id', element: <ParticipantList /> },

        { path: 'organizatori', element: <OrganizerList /> },
        { path: 'organizatori/cursuri/:id', element: <CourseList /> },

        { path: 'cursuri', element: <CourseList /> },
      ]
    }
  ]);

  return element;
}

export default Router;
