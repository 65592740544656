import axios from 'axios';
import { Buffer } from 'buffer';

export default async (data) => {
  try {
    // Save client to main DB
    const response = await axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}clients`,
      headers: {
        Authorization: `Basic ${Buffer.from(process.env.REACT_APP_API_KEY).toString('base64')}`
      },
      data: {
        nume: data.nume,
        prenume: data.prenume,
        mobil: data.mobil,
        email: data.email
      }
    });

    if (response.status === 201) {
      // Save log entry for participation
      await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}client-logs`,
        headers: {
          Authorization: `Basic ${Buffer.from(process.env.REACT_APP_API_KEY).toString('base64')}`
        },
        data: {
          client_id: response.data.id,
          date: Math.round(new Date().getTime() / 1000),
          action: 'curs',
          comment: data.course_name,
          target_client_id: data.organizer_id
        }
      });

      // Save participant
      const participantResponse = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}participants`,
        headers: {
          Authorization: `Basic ${Buffer.from(process.env.REACT_APP_API_KEY).toString('base64')}`
        },
        data: {
          client_id: response.data.id,

          // Saving information from response because it could have been formated before save
          nume: response.data.nume,
          prenume: response.data.prenume,
          mobil: response.data.mobil,

          // Saving email from parameter because could be omited in client creation if duplicate
          email: data.email
        }
      });

      if (participantResponse.status === 201) {
        // Save participant to course edition connection
        const conenctionResponse = await axios({
          method: 'POST',
          url: `${process.env.REACT_APP_API_URL}participant-course-edition-connections`,
          headers: {
            Authorization: `Basic ${Buffer.from(process.env.REACT_APP_API_KEY).toString('base64')}`
          },
          data: {
            participant_id: participantResponse.data.id,
            course_edition_id: data.edition_id,
          }
        });

        if (conenctionResponse.status === 201) {
          return true;
        }

        return participantResponse.data[0].message;
      }

      return participantResponse.data[0].message;
    }

    return response.data[0].message;
  } catch (e) {
    console.error('A aparut o eroare de conexiune: ', e.message);
  }

  return 'A aparut o eroare de conexiune. Va rugam sa incercati din nou mai tarziu.';
};
