import axios from 'axios';
import authHeader from 'src/utils/auth/authHeaders';

class ClientAPI {
  static async search(term) {
    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}clients?filter[term][like]=${term}`,
        headers: authHeader()
      });

      return response.data.items;
    } catch (e) {
      console.error(e.message);
    }

    return [];
  }
}

export default ClientAPI;
