import {
  Alarm,
  CalendarMonth,
  ChevronLeft,
  CoPresent,
  ExitToApp,
  HowToReg,
  InsertLink,
  Payment,
  Person,
  PriceCheck
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography
} from '@mui/material';
import { useQuery } from 'react-query';
import { Fragment } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ParticipantAPI from 'src/services/participant.service';
import EditionAPI from 'src/services/edition.service';
import DeleteButton from 'src/components/general/DeleteButton';
import moment from 'moment';
import OrganizerAPI from 'src/services/organizer.service';
import PayEdition from 'src/components/courses/PayEdition';

function ParticipantList() {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: participants,
    isFetched: participantsFetched,
    refetch: refetchParticipants
  } = useQuery(['getParticipants', id], () => ParticipantAPI.listInEdition(id));
  const { data: edition, isFetched: editionFetched, refetch: refetchEdition } = useQuery(
    ['getEdition', id],
    () => EditionAPI.get(id)
  );

  const { data: organizer, isFetched: organizerFetched } = useQuery(
    ['getOrganizer', edition?.course?.organizer_id],
    () => OrganizerAPI.get(edition?.course?.organizer_id)
  );

  const handleDelete = async (participantId) => {
    await ParticipantAPI.delete(participantId);

    refetchParticipants();
  };

  const handlePaymentSuccess = () => {
    refetchEdition();
  };

  return (
    <>
      <Grid container sx={{ alignItems: 'center', mb: 3 }}>
        <Grid item xs={8}>
          <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ mb: 2 }}>
            <Link to="/cursuri">
              <Typography sx={{ color: '#a3a3a3' }}>Cursuri</Typography>
            </Link>
            <Typography color="#a3a3a3">
              {editionFetched ? edition.course.name : ''}
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={4} sx={{ textAlign: 'right' }}>
          <Button
            onClick={() => navigate('/cursuri')}
            size="small"
            sx={{ mb: 2 }}
          >
            <ChevronLeft />
            &nbsp;Înapoi
          </Button>
        </Grid>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Divider />
        </Grid>
        <Grid item sm={8} xs={12} sx={{ textAlign: 'left', mb: 2 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Participanti
            <Typography variant="body1">
              la editia&nbsp;
              {editionFetched ? <b>{edition.name}</b> : ''}
              &nbsp;a cursului&nbsp;
              {editionFetched ? <b>{edition.course.name}</b> : ''}
            </Typography>
          </Typography>
          { organizerFetched && (
          <Typography color="#747474">
            {editionFetched && edition.credit && (
              <Box
                sx={{
                  display: 'flex', gap: '10px', mb: 1, flexWrap: 'wrap'
                }}
              >
                <CoPresent />
                Organizator:&nbsp;
                <b>{`${organizer.client.nume} ${organizer.client.prenume}`}</b>
              </Box>
            )}
          </Typography>
          )}
          <Typography color="#747474">
            {editionFetched && edition.credit && (
              <Box
                sx={{
                  display: 'flex', gap: '10px', mb: 1, flexWrap: 'wrap'
                }}
              >
                <PriceCheck />
                Suma acordata pe persoana:&nbsp;
                <b>{`${Math.round(edition.credit)} lei`}</b>
              </Box>
            )}
          </Typography>
          {editionFetched && (
            <>
              <Typography color="#747474">
                <Box
                  sx={{
                    display: 'flex', gap: '10px', mb: 1, flexWrap: 'wrap'
                  }}
                >
                  <CalendarMonth />
                  Perioada:&nbsp;
                  <b>{`${moment(edition.start_date).format('DD.MM.YYYY')} - ${moment(edition.end_date).format('DD.MM.YYYY')}`}</b>
                </Box>
              </Typography>
              <Typography color="#747474">
                <Box
                  sx={{
                    display: 'flex', gap: '10px', mb: 1, flexWrap: 'wrap'
                  }}
                >
                  <Alarm />
                  Durata:&nbsp;
                  <b>{`${edition.duration} ore`}</b>
                </Box>
              </Typography>
              { Boolean(edition.payment_date) && (
                <Typography color="#747474">
                  <Box
                    sx={{
                      display: 'flex', gap: '10px', mb: 1, flexWrap: 'wrap'
                    }}
                  >
                    <Payment />
                    Plata efectuata in data de&nbsp;
                    <b>{moment.unix(edition.payment_date).format('DD.MM.YYYY')}</b>
                    &nbsp;cu documentul&nbsp;
                    <b>{edition.payment_proof}</b>
                  </Box>
                </Typography>
              )}
            </>
          )}
          <Typography color="#747474">
            <Box
              sx={{
                display: 'flex', gap: '10px', mb: 1, flexWrap: 'wrap'
              }}
            >
              <HowToReg />
              Nr. participanti:&nbsp;
              <b>{participantsFetched ? participants.length : 0}</b>
            </Box>
          </Typography>
        </Grid>
        <Grid item sm={4} xs={12} sx={{ textAlign: 'right' }}>
          { editionFetched && !edition.payment_proof && (
            <>
              <Box>
                <a
                  href={`/participanti/inregistrare/${edition?.hash}`}
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  <Button
                    startIcon={<InsertLink />}
                    variant="contained"
                    sx={{ mb: 2 }}
                  >
                    Inregistrare participanti
                  </Button>
                </a>
              </Box>
              <Box>
                <PayEdition editionId={edition.id} successCallback={handlePaymentSuccess} />
              </Box>
            </>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          display: { md: 'flex', xs: 'none' },
          mb: 1,
          paddingLeft: '70px',
          paddingRight: '100px',
          textAlign: 'left',
          fontWeight: 'bold'
        }}
      >
        <Grid item xs={7}>
          Nume
        </Grid>
        <Grid item xs={1} sx={{ textAlign: 'center' }}>
          Comenzi
        </Grid>
        <Grid item xs={2} sx={{ textAlign: 'right' }}>
          Valoare
        </Grid>
        <Grid item xs={2} sx={{ textAlign: 'right' }}>
          Adaos
        </Grid>
      </Grid>
      {!participantsFetched ? (
        <CircularProgress />
      ) : (
        <Paper>
          <List>
            {participants.length > 0 ? (
              participants.map((participant, key) => (
                <Fragment key={participant.id}>
                  <a
                    href={`https://admin.sisicosmetice.ro/admin/modificare_client.php?id=${participant.client_id}`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <ListItemButton>
                      <ListItemIcon sx={{ display: { md: 'block', xs: 'none' } }}>
                        <Avatar>
                          <Person />
                        </Avatar>
                      </ListItemIcon>
                      <ListItemText sx={{ color: 'secondary.main' }}>
                        <Grid container sx={{ display: { md: 'flex', xs: 'none' } }}>
                          <Grid item xs={7}>
                            {`${participant.nume} ${participant.prenume}`}
                          </Grid>
                          <Grid item xs={1} sx={{ textAlign: 'center' }}>
                            {participant.order_count}
                          </Grid>
                          <Grid item xs={2} sx={{ textAlign: 'right' }}>
                            {participant.orders_value}
                            &nbsp;lei
                          </Grid>
                          <Grid item xs={2} sx={{ textAlign: 'right' }}>
                            {participant.orders_profit}
                            &nbsp;lei
                          </Grid>
                        </Grid>
                        <Grid container sx={{ display: { md: 'none', xs: 'flex' } }}>
                          <Grid item xs={12}>
                            <b>Nume:&nbsp;</b>
                            {`${participant.nume} ${participant.prenume}`}
                          </Grid>
                          <Grid item xs={12}>
                            <b>Comenzi:&nbsp;</b>
                            {participant.order_count}
                          </Grid>
                          <Grid item xs={12}>
                            <b>Valoare:&nbsp;</b>
                            {participant.orders_value}
                            &nbsp;lei
                          </Grid>
                          <Grid item xs={12}>
                            <b>Adaos:&nbsp;</b>
                            {participant.orders_profit}
                            &nbsp;lei
                          </Grid>
                        </Grid>
                      </ListItemText>
                      <Box sx={{ ml: 2 }}>
                        <DeleteButton
                          handler={() => handleDelete(participant.id)}
                        />
                      </Box>
                      <IconButton>
                        <ExitToApp />
                      </IconButton>
                    </ListItemButton>
                  </a>
                  {key + 1 !== participants.length && <Divider />}
                </Fragment>
              ))
            ) : (
              <Typography>Nu exită participanți</Typography>
            )}
          </List>
        </Paper>
      )}
    </>
  );
}

export default ParticipantList;
