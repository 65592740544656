import axios from 'axios';
import logoutThunk from 'src/redux/features/user/thunks/logoutThunk';
import refreshTokenThunk from 'src/redux/features/user/thunks/refreshTokenThunk';
import store from 'src/redux/store';

export default (navigate) => {
  let isRefreshing = false;
  let refreshSubscribers = [];

  const subscribeTokenRefresh = (cb) => refreshSubscribers.push(cb);

  const onRefreshed = (token) => {
    refreshSubscribers.map((cb) => cb(token));
    refreshSubscribers = [];
  };

  axios.interceptors.response.use(undefined, (error) => {
    const status = error.response ? error.response.status : false;
    const originalRequest = error.config;
    const apiUrl = new URL(error.config.url);
    const apiEndpoint = apiUrl.pathname.substring(apiUrl.pathname.indexOf('/', 2));

    if (apiEndpoint === '/auth/refresh-token') {
      if (error.config.method === 'delete') {
        return true;
      }
      store.dispatch(logoutThunk(navigate, '/login')).then(() => {
        isRefreshing = false;
      });
    }

    if (status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        store
          .dispatch(refreshTokenThunk())
          .then((newToken) => {
            isRefreshing = false;
            onRefreshed(newToken);
          })
          .catch(() => {
            isRefreshing = false;
          });
      }

      return new Promise((resolve) => {
        subscribeTokenRefresh((token) => {
          // replace the expired token and retry
          originalRequest.headers.Authorization = `Bearer ${token}`;
          resolve(axios(originalRequest));
        });
      });
    }

    return Promise.reject(error);
  });
};
